<template>
	<el-dialog
		title=""
		:visible.sync="createdVisible"
		:width="openType === 'dictionaryItem' ? '438px' : '578px'"
		center
		:close-on-click-modal="false"
		:append-to-body="openType === 'dictionaryItem'"
	>
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ !isShowDetail && ruleForm.id ? '编辑' : '新增' }}{{ openType === 'dictionaryItem' ? '字典项' : '字典' }}</span>
		</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item :label="openType === 'dictionaryItem' ? '字典项名称' : '字典名称'" prop="title">
				<el-input v-model.trim="ruleForm.title" placeholder="请输入" :disabled="isShowDetail"></el-input>
			</el-form-item>
			<el-form-item :label="openType === 'dictionaryItem' ? '字典项编码' : '字典编码'" prop="code">
				<el-input v-model.trim="ruleForm.code" placeholder="请输入" :disabled="isShowDetail"></el-input>
			</el-form-item>
			<el-form-item :label="openType === 'dictionaryItem' ? '字典项描述' : '字典描述'" prop="description">
				<el-input v-model.trim="ruleForm.description" placeholder="请输入" :disabled="isShowDetail"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button v-if="!isShowDetail" type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button :type="!isShowDetail ? '' : 'primary'" @click="resetForm('ruleForm')">{{ !isShowDetail ? '取 消' : '确 定' }}</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				title: '',
				code: '',
				description: '',
			},
			rules: {
				title: [{ required: true, message: '请输入', trigger: 'blur' }],
				code: [{ required: true, message: '请输入', trigger: 'blur' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
			},
			saveLoading: false,
			checkDictionRow: {},
			checkRow: {},
		};
	},
	props: ['isShowDetail', 'openType', 'dictionId'],
	created() {},
	methods: {
		init(dictionaryInfo, checkRow = {}) {
			this.checkDictionRow = checkRow;
			this.createdVisible = true;
			if (dictionaryInfo) {
				this.checkRow = dictionaryInfo;
				this.ruleForm = JSON.parse(JSON.stringify(dictionaryInfo));
				this.ruleForm.title = dictionaryInfo.name;
				return;
			}
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let requestType = '';
					if (
						this.ruleForm.id == this.checkRow.id &&
						this.ruleForm.code == this.checkRow.code &&
						this.ruleForm.title == this.checkRow.title &&
						this.ruleForm.description == this.checkRow.description
					) {
						this.$emit('getList');
						this.resetForm('ruleForm');
						return;
					}
					this.saveLoading = true;
					switch (this.openType) {
						case 'createDictionary':
							let par = {
								...this.ruleForm,
								name: this.ruleForm.title,
							};
							requestType = this.ruleForm.id
								? this.$http.put(this.api.getDictionaries + `/${this.ruleForm.id}.json`, par)
								: this.$http.post(this.api.getDictionaries + '.json', par);
							break;
						case 'dictionaryItem':
							let data = {
								...this.ruleForm,
								dictionaryId: this.checkDictionRow.id,
							};
							requestType = this.ruleForm.id
								? this.$http.put(this.api.getDictionaryItems + `/${this.ruleForm.id}.json`, data)
								: this.$http.post(this.api.getDictionaryItems + '.json', data);
							break;
						default:
							break;
					}
					requestType
						.then((res) => {
							if (res.data && res.data.success) {
								this.saveLoading = false;
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								if (this.openType === 'dictionaryItem') {
									this.$emit('getTableData', this.checkDictionRow);
								} else {
									this.$emit('getList');
								}
								this.resetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.createdVisible = false;
		},
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #5374f0;
	}
}
/deep/ .el-form {
	.el-form-item {
		margin-right: 0;
		.el-select,
		.el-input {
			width: 100%;
		}
	}
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .el-button {
	margin-left: 16px;
}
</style>